import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { SignedIn, SignedOut, UserButton } from "@clerk/clerk-react";
import { useLocation, useNavigate } from "react-router-dom";

export const TopNavbar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <SignedIn>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            marginLeft: "auto",
          }}
        >
          <UserButton afterSignOutUrl="/sign-in" />
        </Box>
      </SignedIn>
      <SignedOut>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open("https://devmark.ai");
            }}
          >
            <img
              src="/devmark_icon.png"
              alt=""
              style={{
                height: 35,
                width: 35,
                marginLeft: 16,
                alignSelf: "center",
                filter: "invert(1)",
                WebkitFilter: "invert(1)",
              }}
            ></img>
            <Typography
              variant="h5"
              sx={{
                paddingLeft: "12px",
                alignSelf: "end",
                color: "black",
                flexShrink: 0,
                flexGrow: 0,
              }}
            >
              devmark.ai
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 2, flexDirection: "row" }}>
            <Button
              onClick={() => {
                const path = location.pathname + location.search;
                navigate(`/sign-in?redirectUrl=${encodeURIComponent(path)}`);
              }}
            >
              Login
            </Button>
            <Button
              variant={"contained"}
              onClick={() => {
                const path = location.pathname + location.search;
                navigate(`/sign-up?redirectUrl=${encodeURIComponent(path)}`);
              }}
            >
              Sign up
            </Button>
          </Box>
        </Box>
      </SignedOut>
    </>
  );
};
