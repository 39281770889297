import axios from "axios";

import { LocalStorage } from "ttl-localstorage";

// 1 day expiration
LocalStorage.timeoutInSeconds = 86400;

export interface RepoData {
  full_name: string;
  owner: {
    avatar_url: string;
  };
  description: string;
  stargazers_count: number;
  pushed_at: string;
  default_branch: string;
  repo_accept_sponsorship?: boolean;
}

async function _fetchRepoData(
  orgName: string,
  projectName: string
): Promise<RepoData | undefined> {
  const query = `
  query {
    repository(owner: "${orgName}", name: "${projectName}") {
      description
      owner {
        avatarUrl
      }
      stargazers {
        totalCount
      }
      fundingLinks{
        platform
        url
      }
      pushedAt
      defaultBranchRef {
        name
      }
    }
  }
`;

  const resp = await axios.post(
    "https://api.github.com/graphql",
    { query },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`,
      },
    }
  );

  const respData = resp.data.data.repository;
  if (!respData) {
    console.log(`Failed to fetch ${orgName}/${projectName}`);
    return;
  }

  return {
    full_name: `${orgName}/${projectName}`,
    owner: {
      avatar_url: `${respData.owner?.avatarUrl}`,
    },
    description: respData.description,
    stargazers_count: respData.stargazers?.totalCount,
    pushed_at: respData.pushedAt,
    repo_accept_sponsorship: respData.fundingLinks.length > 0,
    default_branch: respData.defaultBranchRef?.name ?? "main",
  };
}

export async function fetchRepoData(
  orgName: string,
  projectName: string
): Promise<RepoData | undefined> {
  const key = `github/${orgName}/${projectName}`;

  const localObject = LocalStorage.get(key);

  if (localObject) {
    try {
      return JSON.parse(localObject) as RepoData;
    } catch (e) {
      console.log(e);
    }
  }

  const data = await _fetchRepoData(orgName, projectName);
  if (data) {
    LocalStorage.put(key, JSON.stringify(data));
  }
  return data;
}
