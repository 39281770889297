import { RepoData } from "../lib/fetchRepoData";

import { Box, Tooltip, Typography } from "@mui/material";

export const RepoMetadata: React.FC<RepoData> = (repoData) => {
  const pushedDate = new Date(repoData.pushed_at);

  const fullName = repoData.full_name;
  const [orgName, repoName] = fullName.split("/");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          margin: 2,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(`https://github.com/${fullName}`);
          }}
        >
          <img
            src={repoData.owner.avatar_url}
            alt="logo"
            width="30"
            height="30"
          />
          <Typography variant="h5">{orgName} /</Typography>
          <Typography variant="h5" fontWeight={"bold"}>
            {repoName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
          }}
        >
          <iframe
            src={`https://ghbtns.com/github-btn.html?user=${encodeURIComponent(
              orgName
            )}&repo=${encodeURIComponent(repoName)}&type=star&count=true`}
            frameBorder="0"
            scrolling="0"
            width="120"
            height="20"
            title="GitHub"
          ></iframe>
          <div
            onClick={() => {
              window.open(`https://star-history.com/#${fullName}&Date`);
            }}
          >
            <Tooltip title="See star history">
              <img src="/star-history.png" alt="" width="20" height="20" />
            </Tooltip>
          </div>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", margin: 2 }}>
        <Typography color="grey">
          Last pushed on {pushedDate.toDateString()}
        </Typography>
      </Box>
    </>
  );
};
