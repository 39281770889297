import {
  Box,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { ProjectData } from "../pages/SearchResult";
import { useUser } from "@clerk/clerk-react";
import emailProviders from "email-providers/all.json";
import dayjs, { Dayjs } from "dayjs";
import { useHistoryState } from "../lib/useHistoryState";

export interface AdvancedFilters {
  nonEnglish?: boolean; // undefined = don't care
  lastCommitDate?: string; // YYYY-MM-DD, undefined = don't care
  minStarCount?: number; // undefined = min 500
  haveFundingLinks: boolean;
  starGrowthTier?: string; // undefined = any
}

export interface AudienceFormProps {
  projectData?: ProjectData;
  readOnly: boolean;
  handleSubmit?: (
    product: string,
    audience: string,
    website: string,
    advancedFilters: AdvancedFilters
  ) => void;
}

function isCommonEmailService(domain: string) {
  return emailProviders.includes(domain);
}

function extractDomainFromEmail(email: string) {
  const parts = email.split("@");
  if (parts.length !== 2) {
    // Invalid email address
    return undefined;
  }

  if (isCommonEmailService(parts[1])) {
    return undefined;
  }

  if (
    parts[1] === "usecyclone.dev" ||
    parts[1] === "devmark.ai" ||
    parts[1] === "getmentioned.ai"
  )
    return undefined;

  return parts[1];
}

export const AudienceForm: React.FC<AudienceFormProps> = ({
  projectData,
  readOnly,
  handleSubmit,
}) => {
  const [product, setProduct] = useHistoryState<string>("product", "");
  const [productError, setProductError] = useState<boolean>(false);
  const [audience, setAudience] = useHistoryState<string>("audience", "");
  const [audienceError, setAudienceError] = useState<boolean>(false);
  const [website, setWebsite] = useHistoryState<string>("website", "");
  const [websiteError, setWebsiteError] = useState<boolean>(false);
  const [minStar, setMinStar] = useState<number>(0);
  const [starGrowthTier, setStarGrowthTier] = useState<string>("any");
  const [lastCommitDate, setLastCommitDate] = useState<Dayjs | null>(
    dayjs().subtract(3, "month")
  );
  const [englishOnly, setEnglishOnly] = useState<boolean>(true);
  const [haveFundingLinks, setHaveFundingLinks] = useState<boolean>(false);

  const { user } = useUser();
  const email = user?.emailAddresses[0]?.emailAddress ?? "";
  const domain = extractDomainFromEmail(email);

  console.log(product, audience, website);

  useEffect(() => {
    if (domain !== undefined) {
      setWebsite(`https://${domain}`);
    }
  }, [domain, setWebsite]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Describe target audience
      </Typography>

      <form
        onSubmit={
          handleSubmit !== undefined
            ? (event) => {
                event.preventDefault();

                const isValidUrl = (urlString: string) => {
                  var urlPattern = new RegExp(
                    "^(https?:\\/\\/)?" + // validate protocol
                      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
                      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
                      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
                      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
                      "(\\#[-a-z\\d_]*)?$",
                    "i"
                  ); // validate fragment locator
                  return !!urlPattern.test(urlString);
                };

                setProductError(product === "");
                setAudienceError(audience === "");
                setWebsiteError(website === "" || !isValidUrl(website));

                if (
                  product === "" ||
                  audience === "" ||
                  website === "" ||
                  !isValidUrl(website)
                ) {
                  return;
                }

                handleSubmit(product, audience, website, {
                  nonEnglish: englishOnly ? false : undefined,
                  lastCommitDate:
                    lastCommitDate?.format("YYYY-MM-DD") ??
                    dayjs().subtract(3, "month").format("YYYY-MM-DD"),
                  minStarCount: minStar,
                  haveFundingLinks,
                  starGrowthTier:
                    starGrowthTier === "any" ? undefined : starGrowthTier,
                });
              }
            : (event) => {}
        }
      >
        <TextField
          label="My Product Is:"
          defaultValue={projectData?.product_description ?? product}
          margin="normal"
          fullWidth
          onChange={(e) => setProduct(e.target.value)}
          InputProps={{
            readOnly: readOnly,
          }}
          error={productError}
          helperText={productError ? "This field is required" : ""}
        />
        <TextField
          label="Website is:"
          defaultValue={domain !== undefined ? `https://${domain}` : website}
          margin="normal"
          fullWidth
          onChange={(e) => setWebsite(e.target.value)}
          InputProps={{
            readOnly: readOnly,
          }}
          error={websiteError}
          helperText={
            websiteError ? "This field is required and must be a valid URL" : ""
          }
        />
        <TextField
          label="I want to reach:"
          defaultValue={projectData?.user_description ?? audience}
          margin="normal"
          fullWidth
          onChange={(e) => setAudience(e.target.value)}
          InputProps={{
            readOnly: readOnly,
          }}
          error={audienceError}
          helperText={audienceError ? "This field is required" : ""}
        />
        <Accordion
          sx={{
            marginTop: 2,
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography color="grey">Advanced Filters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: 1,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    value={haveFundingLinks}
                    onChange={(event) => {
                      setHaveFundingLinks(event.target.checked);
                    }}
                  />
                }
                label="Only consider projects listed on sponsorships platforms"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    value={englishOnly}
                    onChange={(event) => {
                      setEnglishOnly(event.target.checked);
                    }}
                  />
                }
                label="Only consider English projects"
              />
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">
                  Minimum star count
                </InputLabel>
                <Select
                  label="Star count"
                  value={minStar}
                  onChange={(v) => {
                    setMinStar(v.target.value as number);
                  }}
                >
                  <MenuItem value={0}>Any</MenuItem>
                  <MenuItem value={500}>500+ stars</MenuItem>
                  <MenuItem value={1000}>1,000+ stars</MenuItem>
                  <MenuItem value={2000}>2,000+ stars</MenuItem>
                  <MenuItem value={5000}>5,000+ stars</MenuItem>
                  <MenuItem value={10000}>10,000+ stars</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">
                  Growth rate percentile
                </InputLabel>
                <Select
                  label="Growth rate percentile"
                  value={starGrowthTier}
                  onChange={(v) => {
                    setStarGrowthTier(v.target.value as string);
                  }}
                >
                  <MenuItem value={"any"}>Any</MenuItem>
                  <MenuItem value={"top_10_percent"}>Top 10%</MenuItem>
                  <MenuItem value={"top_25_percent"}>Top 25%</MenuItem>
                  <MenuItem value={"top_50_percent"}>Top 50%</MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={lastCommitDate}
                  onChange={(newValue) => setLastCommitDate(newValue)}
                  label="Lastest commit pushed to main branch"
                />
              </LocalizationProvider>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box sx={{ display: "flex" }}>
          <Button
            variant="contained"
            type="submit"
            startIcon={<SearchIcon />}
            sx={{ marginTop: 4, marginLeft: "auto", marginRight: "auto" }}
          >
            Create package
          </Button>
        </Box>
      </form>
    </Box>
  );
};
