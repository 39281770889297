import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { GitHubCardProps } from "../components/GitHubCard";
import { useLocation, useNavigate } from "react-router-dom";
import LabelIcon from "@mui/icons-material/Label";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/Person";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { useUser } from "@clerk/clerk-react";
import { Tooltip } from "@mui/material";
import { upsertUserInput } from "../lib/userInput";
import { SquareGitHubCard } from "./SquareGitHubCard";
import {
  deleteSelectedRepo,
  getSelectedRepo,
  saveSelectedRepo,
} from "../lib/proposalStorage";

function repoToProject(repo: Repo): GitHubCardProps {
  return {
    repoUrl: `https://github.com/${repo.name}`,
  };
}

const MetricBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginRight: "8px",
});

// Repo is the data structure returned by /api/repos/search
export interface Repo {
  archived: boolean;
  cosine_distance: number;
  creation_date: string;
  description: string;
  fork: boolean;
  fork_count: number;
  homepage_link: string | null;
  id: number;
  last_update_date: string;
  maintainer_id: string;
  name: string;
  org_id: string;
  primary_language: string;
  readme_url: string;
  repo_accept_sponsorship: boolean;
  star_count: number;
  url: string;
  watching: number;
}

export interface ProposalDetailsProps {
  repos: Repo[];
  product: string;
  website: string;
  audience: string;
}

async function saveProposal(
  repos: Repo[],
  userId: string,
  product: string,
  website: string,
  audience: string
) {
  const repoToSave = repos.map((repo) => {
    return { repo: repo.name };
  });

  const resp = await fetch("/api/proposals/proposal", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      repos: repoToSave,
      userId,
      product,
      website,
    }),
  });

  if (!resp.ok) {
    console.log(`Error: ${resp.status}`);
    throw new Error(`HTTP error! Status: ${resp.status}`);
  }

  const data = await resp.json();
  const { enrichedProduct } = data;

  if (enrichedProduct) {
    upsertUserInput(enrichedProduct, audience);
  }
}

export const ProposalDetails: React.FC<ProposalDetailsProps> = ({
  repos,
  product,
  website,
  audience,
}) => {
  const { user } = useUser();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [seletedRepoNames, setSeletedRepoNames] = useState<string[]>(
    getSelectedRepo()
  );

  const navigate = useNavigate();
  const location = useLocation();

  const [rng, setRng] = useState<number>(Math.random());
  function scaledHashToRandom(input: number) {
    const MAX_INT = 2 ** 31 - 1; // Maximum signed 32-bit integer
    let hash = input;
    hash = ((hash >> 16) ^ hash) * 0x45d9f3b;
    hash = ((hash >> 16) ^ hash) * 0x45d9f3b;
    hash = (hash >> 16) ^ hash;
    return (Math.abs(hash) % MAX_INT) / MAX_INT;
  }

  const selectedRepos = repos.filter((r) => seletedRepoNames.includes(r.name));
  const candidateRepos = repos.filter(
    (r) => !seletedRepoNames.includes(r.name)
  );

  const stars = selectedRepos.reduce((acc, repo) => acc + repo.star_count, 0);
  // Fake for now, these can be fetched from the backend later
  const logoPlacementPrice = (stars / 1000) * 50;
  const paragraphAdPrice = logoPlacementPrice + 27;
  const monthlyImpressions = stars * 5;
  const monthlyUniques = (monthlyImpressions / 4.1).toFixed(0);
  const CPM =
    0.3 * (logoPlacementPrice / (monthlyImpressions / 1000)) * 1.2 +
    scaledHashToRandom(monthlyImpressions) * 2;
  const CPC =
    0.2 * (logoPlacementPrice / (Number(monthlyUniques) * 0.002)) * 0.8 +
    scaledHashToRandom(monthlyImpressions) * 4;
  return (
    <>
      {user ? (
        <Typography variant="h4" gutterBottom>
          Build your sponsorship package
        </Typography>
      ) : (
        <>
          <Typography variant="h4">
            Preview sponsorship opportunities
          </Typography>
          <Typography variant="h6" color="grey" gutterBottom>
            Sign up for free to save the sponsorship list and start the go live
            process
          </Typography>
        </>
      )}

      <Paper
        sx={{
          marginBottom: 2,
          p: 2,
          border: 1,
          borderColor: "rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Selected repos
          </Typography>
          <Box sx={{ gap: 1, display: "flex" }}>
            {user ? (
              <Button
                variant="contained"
                color="success"
                disabled={selectedRepos.length === 0}
                onClick={async () => {
                  setIsSaving(true);
                  await saveProposal(
                    selectedRepos,
                    user?.id!,
                    product,
                    website,
                    audience
                  );
                  await user?.reload();
                  navigate("/manage");
                }}
              >
                {isSaving ? (
                  <CircularProgress size="27px" color="inherit" />
                ) : (
                  "Save package"
                )}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                disabled={selectedRepos.length === 0}
                onClick={() => {
                  const path = location.pathname + location.search;
                  navigate(`/sign-up?redirectUrl=${encodeURIComponent(path)}`);
                }}
              >
                Sign up and save
              </Button>
            )}

            <Button
              variant="outlined"
              color="error"
              onClick={() => navigate("/new")}
            >
              New search
            </Button>
          </Box>
        </Box>
        <Grid container xs={12} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          {selectedRepos.map((repo) => (
            <Grid item xs={4}>
              <SquareGitHubCard
                {...repoToProject(repo)}
                isSelected={true}
                onSelected={() => {
                  setSeletedRepoNames((prev) =>
                    prev.filter((name) => name !== repo.name)
                  );
                  deleteSelectedRepo(repo.name);
                  setRng(Math.random());
                }}
                key={repo.url}
              />
            </Grid>
          ))}
        </Grid>
        {selectedRepos.length === 0 ? (
          <></>
        ) : (
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 2,
            }}
          >
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              sx={{ display: "inline-flex", width: "auto" }}
            >
              <Tooltip title="Estimated CPM">
                <MetricBox>
                  <VisibilityIcon
                    style={{ color: "green", marginRight: "4px" }}
                  />
                  <span>${CPM.toFixed(2)}</span>
                </MetricBox>
              </Tooltip>

              <Tooltip title="Estimated CPC">
                <MetricBox>
                  <TouchAppIcon
                    style={{ color: "green", marginRight: "4px" }}
                  />
                  <span>${CPC.toFixed(2)}</span>
                </MetricBox>
              </Tooltip>

              <Tooltip title="Total Price">
                <MetricBox>
                  <LabelIcon style={{ color: "green", marginRight: "4px" }} />
                  <span>${logoPlacementPrice.toFixed(2)}</span>
                </MetricBox>
              </Tooltip>

              <Tooltip title="Monthly Impressions">
                <MetricBox>
                  <TrendingUpIcon
                    style={{ color: "green", marginRight: "4px" }}
                  />
                  <span>{monthlyImpressions}</span>
                </MetricBox>
              </Tooltip>

              <Tooltip title="Monthly Unique Impressions">
                <MetricBox>
                  <PersonIcon style={{ color: "green", marginRight: "4px" }} />
                  <span>{monthlyUniques}</span>
                </MetricBox>
              </Tooltip>
            </Grid>
          </Box>
        )}
      </Paper>

      <Paper
        sx={{
          marginBottom: 2,
          p: 1,
          border: 1,
          borderColor: "rgba(0, 0, 0, 0.12)",
          backgroundColor: "#fafafa",
        }}
      >
        <Typography variant="h6" fontWeight={"bold"} gutterBottom>
          Results
        </Typography>
        <Grid container xs={12} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          {candidateRepos.map((repo) => (
            <Grid item xs={4}>
              <SquareGitHubCard
                {...repoToProject(repo)}
                onSelected={() => {
                  setSeletedRepoNames([...seletedRepoNames, repo.name]);
                  saveSelectedRepo(repo.name);
                  setRng(Math.random());
                }}
                isSelected={false}
                key={repo.url}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </>
  );
};
