import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Link,
  Box,
  List,
  ListItem,
  Grid,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import WarningIcon from "@mui/icons-material/Warning";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/Send";
import HandshakeIcon from "@mui/icons-material/Handshake";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LockIcon from "@mui/icons-material/Lock";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import { styled } from "@mui/system";
import { RepoData, fetchRepoData } from "../lib/fetchRepoData";
import { SuggestionPopup } from "./SuggestionPopup";
import Check from "@mui/icons-material/Check";

export interface GitHubProgressCardProps {
  repoUrl: string;
  sponsorship_opportunities?: string[];
  sponsorship_example?: string;
  dollar_amount?: string;
  example_popup?: {
    title?: string;
    old_code: string;
    new_code: string;
  };
  next_step?: string;
  show_audience?: boolean;
  hide_sponsorship_button?: boolean;
  show_replace_button?: boolean;
  show_placement_suggestion?: boolean;
  onReplace?: () => void;
  product_description?: string; // product description
  prefetch?: boolean;
  showMetrics?: boolean;
}

const REPO_STALE_THRESHOLD_DAYS = 90;

const boxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const LogoAndName = styled("div")({
  display: "flex",
  alignItems: "center",
});

const StyledLink = styled(Link)({
  marginLeft: "8px",
  fontWeight: "bold",
});

const Description = styled(Typography)({
  color: "grey",
  margin: "8px 0",
});

const GreyBox = styled(Box)({
  background: "lightgrey",
  padding: "15px",
  borderRadius: "8px",
});

const ButtonsContainer = styled("div")({
  "& > button": {
    marginLeft: "8px",
  },
});

const SectionTitle = styled(Typography)({
  fontWeight: "bold",
});

const MetricBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginRight: "8px",
});

export const GitHubProgressCard: React.FC<GitHubProgressCardProps> = ({
  repoUrl,
  sponsorship_opportunities,
  sponsorship_example,
  dollar_amount,
  example_popup,
  next_step,
  show_audience,
  hide_sponsorship_button,
  show_replace_button,
  show_placement_suggestion,
  onReplace,
  product_description,
  prefetch,
  showMetrics,
}) => {
  const [repoData, setRepoData] = useState<RepoData | undefined>(undefined);
  const [finishedFetching, setFinishedFetching] = useState(false);
  const [audience, setAudience] = useState<any>(undefined);
  const [popupOpen, setPopupOpen] = useState(false);
  const [codePopupOpen, setCodePopupOpen] = useState(false);
  const [suggestionPopupOpen, setSuggestionPopupOpen] = useState(false);

  const [orgName, projectName] = repoUrl.split("/").slice(-2);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleOpenCodePopup = () => {
    setCodePopupOpen(true);
  };

  const handleCloseCodePopup = () => {
    setCodePopupOpen(false);
  };

  const handleSuggestionClick = () => {
    setSuggestionPopupOpen(true);
  };

  const handleSuggestionCodeClose = () => {
    setSuggestionPopupOpen(false);
  };

  useEffect(() => {
    if (repoData !== undefined) return;

    fetchRepoData(orgName, projectName).then((data) => {
      setRepoData(data);
      setFinishedFetching(true);
    });
  }, [orgName, projectName, repoData]);

  useEffect(() => {
    if (show_audience) {
      const fetchAudienceData = async () => {
        try {
          const response = await fetch(
            `https://api.devmark.ai/audience_prediction`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                github_repo_url: repoUrl,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("audience_prediction is not ok");
          }

          const { result } = await response.json();
          const { audience_predecition } = result;
          if (audience_predecition !== undefined) {
            setAudience(audience_predecition);
          }
        } catch (e) {
          console.error(e);
          setAudience("");
          return;
        }
      };

      fetchAudienceData();
    } else {
      setAudience("");
    }
  }, [repoUrl, show_audience]);

  if (!repoData && finishedFetching) {
    // error fetching the repo

    return <></>;
  }

  if ((!repoData && !finishedFetching) || audience === undefined)
    return (
      <Card
        sx={{
          width: "100%",
          marginBottom: 2,
          p: 2,
          border: "1px solid rgba(0, 0, 0, 0.12)",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Card>
    );

  const repoDataNotNull = repoData!;
  const currentDate = new Date();
  const pushedDate = new Date(repoDataNotNull.pushed_at);
  const repoStaleDays = Math.floor(
    (currentDate.getTime() - pushedDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  const stars = repoDataNotNull.stargazers_count;
  function scaledHashToRandom(input: number) {
    const MAX_INT = 2 ** 31 - 1; // Maximum signed 32-bit integer
    let hash = input;
    hash = ((hash >> 16) ^ hash) * 0x45d9f3b;
    hash = ((hash >> 16) ^ hash) * 0x45d9f3b;
    hash = (hash >> 16) ^ hash;
    return (Math.abs(hash) % MAX_INT) / MAX_INT;
  }
  // Fake for now, these can be fetched from the backend later
  const logoPlacementPrice = (stars / 1000) * 50;
  const paragraphAdPrice = logoPlacementPrice + 27;
  const monthlyImpressions = stars * 5;
  const monthlyUniques = (monthlyImpressions / 4.1).toFixed(0);
  const CPM =
    0.4 * (logoPlacementPrice / (monthlyImpressions / 1000)) * 1.2 +
    scaledHashToRandom(monthlyImpressions) * 2;
  const CPC =
    0.3 * (logoPlacementPrice / (Number(monthlyUniques) * 0.002)) * 0.8 +
    scaledHashToRandom(monthlyImpressions) * 4;

  const SponsorshipStepLocked = (text: string) => (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        paddingTop: "10px",
      }}
    >
      {text}
      <Tooltip title="Growth plan only feature">
        <LockIcon sx={{ height: 16, color: "orange", marginLeft: "4px" }} />
      </Tooltip>
    </Box>
  );

  return (
    <Card
      sx={{
        width: "100%",
        marginBottom: 2,
        p: 2,
        border: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <CardContent>
        <Grid container columnSpacing={1}>
          <Grid
            item
            xs={8}
            md={8}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <LogoAndName>
                <img
                  src={repoDataNotNull.owner.avatar_url}
                  alt="logo"
                  width="30"
                  height="30"
                />
                <StyledLink
                  href={repoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {repoDataNotNull.full_name}
                </StyledLink>
              </LogoAndName>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {repoData?.repo_accept_sponsorship && (
                  <Tooltip title="Accept sponsorship publicly">
                    <AttachMoneyIcon color={"success"} />
                  </Tooltip>
                )}

                <StyledLink
                  href={`https://star-history.com/${orgName}/${projectName}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {stars} stars
                </StyledLink>
              </Box>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
              <Description>{repoDataNotNull.description}</Description>
              {repoStaleDays > REPO_STALE_THRESHOLD_DAYS && (
                <Box display={"flex"} gap={1} marginTop={2}>
                  <WarningIcon sx={{ color: "orange" }} />
                  <Typography>
                    Last commit pushed {repoStaleDays} days ago
                  </Typography>
                </Box>
              )}
              {sponsorship_opportunities && (
                <>
                  <SectionTitle>Promote your product with:</SectionTitle>
                  <List sx={{ p: 0 }}>
                    {sponsorship_opportunities.map((opportunity, index) => (
                      <ListItem sx={{ p: 0 }} key={index}>
                        ✅ {opportunity}
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            sx={{ justifyItems: "center", display: "flex" }}
          >
            <Box sx={{ margin: "auto" }}>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot color="success">
                      <CheckIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        paddingTop: "10px",
                      }}
                    >
                      Select package
                    </Box>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot>
                      <SendIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {SponsorshipStepLocked("Devmark reaches out")}
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot>
                      <HandshakeIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {SponsorshipStepLocked("Negotiate with maintainer")}
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot>
                      <FavoriteIcon />
                    </TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent>
                    {SponsorshipStepLocked("Sponsorship go live")}
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Box>
          </Grid>
          <Grid
            item
            xs={9}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "flex-end",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{ marginBottom: "16px", alignSelf: "end", textAlign: "end" }}
            >
              {dollar_amount}
            </Typography>
            <ButtonsContainer sx={{ display: "flex", flexDirection: "column" }}>
              {show_placement_suggestion && product_description && (
                <Button
                  variant="contained"
                  color={next_step === undefined ? "success" : "primary"}
                  onClick={handleSuggestionClick}
                  sx={{
                    marginBottom: "0px",
                    marginLeft: "0px",
                    alignSelf: "end",
                    minWidth: "80%",
                  }}
                >
                  Suggest placement
                </Button>
              )}
              {/* {<Button
                sx={{
                  paddingTop: "10%",
                  justifyContent: "right",
                  fontWeight: "bold",
                  paddingBottom: "0px",
                }}
                onClick={() => setShowMetrics(!showMetrics)}>
                Details
              </Button>} */}
            </ButtonsContainer>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
