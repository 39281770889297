// SlidingDiffPopup.tsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
} from "react-compare-slider";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

import Markdown from "react-markdown";

const REACT_DIFF_STYLE = {
  variables: {
    dark: {
      addedBackground: "#e6ffed",
      addedColor: "black",
      removedBackground: "#ffeef0",
      removedColor: "black",
    },
    light: {
      addedBackground: "#e6ffed",
      addedColor: "black",
      removedBackground: "#ffeef0",
      removedColor: "black",
    },
  },
  line: {
    padding: "10px 2px",
  },
  gutter: {
    minWidth: "1em",
  },
  contentText: {
    color: "black",
  },
  content: {
    paddingRight: "10px",
  },
  codeDiffContainer: {
    width: "100%", // Ensures the container takes up the full width available
  },
  "& .diff-viewer-pane": {
    width: "50%", // Ensures each pane takes up half of the container width
  },
};

interface SlidingDiffPopupProps {
  open: boolean;
  onClose: () => void;

  title?: string;
  oldCode: string;
  newCode: string;
}

const SlidingDiffPopup: React.FC<SlidingDiffPopupProps> = ({
  open,
  onClose,
  title,
  oldCode,
  newCode,
}) => {
  const [showDiff, setShowDiff] = React.useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDiff(event.target.checked);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" color="grey">
            {"Proposed change"}
            {title === undefined ? "" : ` to ${title}`}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={<Switch onChange={handleChange} />}
              label="Show git diff"
            />
          </FormGroup>
        </Box>
      </DialogTitle>
      <DialogContent
        style={{ width: "100%", overflowX: "auto", alignSelf: "center" }}
        dividers
      >
        {showDiff ? (
          <ReactDiffViewer
            oldValue={oldCode}
            newValue={newCode}
            splitView={false}
            compareMethod={DiffMethod.LINES} // You can also use DiffMethod.WORDS or DiffMethod.CHARS
            styles={REACT_DIFF_STYLE}
            useDarkTheme={false} // Change this to true for a dark theme
            showDiffOnly={true}
          />
        ) : (
          <Box
            sx={{
              border: 1,
              borderColor: "lightgrey",
              paddingLeft: 2,
              paddingRight: 2,
              borderRadius: "20px",
            }}
          >
            <ReactCompareSlider
              handle={<ReactCompareSliderHandle style={{ color: "green" }} />}
              position={50}
              itemOne={
                <Box sx={{ maxWidth: "100%", backgroundColor: "white" }}>
                  <Markdown>{oldCode}</Markdown>
                </Box>
              }
              itemTwo={
                <Box sx={{ maxWidth: "100%", backgroundColor: "white" }}>
                  <Markdown>{newCode}</Markdown>
                </Box>
              }
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SlidingDiffPopup;
