// storing user input in the frontend session storage

export function upsertUserInput(product: string, audience: string) {
  localStorage.setItem("product", product);
  localStorage.setItem("audience", audience);
}

export function fetchUserInput() {
  return {
    product: localStorage.getItem("product"),
    audience: localStorage.getItem("audience"),
  };
}
