// SideNavbar.tsx

import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Divider,
  ListItemIcon,
  Button,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import DashboardIcon from "@mui/icons-material/Dashboard";
import React from "react";
import { useNavigate } from "react-router-dom";
import UpgradePopup from "./UpgradePopup";
import { useUser } from "@clerk/clerk-react";

export const SideNavbar: React.FC = () => {
  const navigate = useNavigate();

  const [popupOpen, setPopupOpen] = React.useState(false);

  const { user } = useUser();

  const repos = (user?.publicMetadata as any)?.repos;

  return (
    <Box
      sx={{
        backgroundColor: "#000033",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", paddingTop: "16px" }}>
        <img
          src="/devmark_icon.png"
          alt=""
          style={{
            height: 35,
            width: 35,
            marginLeft: 16,
            alignSelf: "center",
          }}
        ></img>
        <Typography
          variant="h5"
          sx={{
            paddingLeft: "12px",
            alignSelf: "end",
            color: "white",
            flexShrink: 0,
            flexGrow: 0,
          }}
        >
          devmark.ai
        </Typography>
      </Box>
      <Divider sx={{ marginBottom: "16px" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <List sx={{ width: "100%", flexGrow: 1 }}>
          <ListItem
            button
            sx={{ justifyContent: "flex-start" }}
            onClick={() => navigate("/")}
          >
            <ListItemIcon sx={{ width: "auto", color: "white" }}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary="Home"
              primaryTypographyProps={{ color: "white" }}
            />
          </ListItem>
          <ListItem
            button
            sx={{ justifyContent: "flex-start" }}
            onClick={() => navigate("/new")}
          >
            <ListItemIcon sx={{ width: "auto", color: "white" }}>
              <PlaylistAddIcon />
            </ListItemIcon>
            <ListItemText
              primary="New Sponsorships"
              primaryTypographyProps={{ color: "white" }}
            />
          </ListItem>
          <ListItem
            button
            sx={{ justifyContent: "flex-start" }}
            onClick={() => navigate("/manage")}
          >
            <ListItemIcon sx={{ width: "auto", color: "white" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              primary="Manage Sponsorships"
              primaryTypographyProps={{ color: "white" }}
            />
          </ListItem>
          {/*<ListItem button sx={{ justifyContent: "flex-start" }}>
          <ListItemIcon sx={{ width: "auto", color: "white" }}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Settings"
            primaryTypographyProps={{ color: "white" }}
          />
        </ListItem>*/}
        </List>
        {repos && (
          <Button
            color="success"
            variant="contained"
            sx={{
              margin: 3,
              padding: "4px",
            }}
            onClick={() => {
              setPopupOpen(true);
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                src="/gradient_gold_royal_crown-removebg-preview.png"
                alt=""
                style={{
                  height: 30,
                  width: 30,
                  marginRight: 4,
                  alignSelf: "center",
                }}
              ></img>
              <Typography color="white" variant="h6">
                Upgrade
              </Typography>
            </Box>
          </Button>
        )}
      </Box>
      <UpgradePopup
        open={popupOpen}
        onClose={() => {
          setPopupOpen(false);
        }}
      />
    </Box>
  );
};
