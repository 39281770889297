import { Buffer } from "buffer";

export async function fetchRepoReadme(repoName: string): Promise<string> {
  // We use REACT_APP_GITHUB_TOKEN to avoid hitting rate limit
  // TODO: replace with a backend API since we will hit rate limit eventually
  const headers: any =
    process.env.REACT_APP_GITHUB_TOKEN === undefined
      ? {
          "X-GitHub-Api-Version": "2022-11-28",
          Accept: "application/vnd.github+json",
        }
      : {
          "X-GitHub-Api-Version": "2022-11-28",
          Accept: "application/vnd.github+json",
          Authorization: `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`,
        };

  // sometimes we get HTTP 403 due to hitting rate limit
  const response = await fetch(
    `https://api.github.com/repos/${repoName}/readme`,
    {
      headers: headers,
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch ${repoName}: ${response.statusText}`);
  }

  const { encoding, content } = await response.json();

  if (encoding !== "base64") {
    throw new Error(`Unsupported encoding: ${encoding}`);
  }

  return Buffer.from(content, "base64").toString("utf-8");
}
