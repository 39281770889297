import React from "react";
import { AdvancedFilters, AudienceForm } from "../components/AudienceForm";
import { Box, Paper, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";

export const NewSearch: React.FC = () => {
  const navigate = useNavigate();
  const handleSubmit = (
    product: string,
    audience: string,
    website: string,
    advancedFilters: AdvancedFilters
  ) => {
    const queryObject: any = {
      product: product,
      audience: audience,
      website: website,
    };

    if (advancedFilters.nonEnglish !== undefined) {
      queryObject["nonEnglish"] = advancedFilters.nonEnglish;
    }

    if (advancedFilters.lastCommitDate) {
      queryObject["lastCommitDate"] = advancedFilters.lastCommitDate;
    }

    if (advancedFilters.minStarCount && advancedFilters.minStarCount > 0) {
      queryObject["minStarCount"] = advancedFilters.minStarCount;
    }

    if (advancedFilters.haveFundingLinks) {
      queryObject["haveFundingLinks"] = advancedFilters.haveFundingLinks;
    }

    if (advancedFilters.starGrowthTier) {
      queryObject["starGrowthTier"] = advancedFilters.starGrowthTier;
    }

    const params = new URLSearchParams(queryObject);

    navigate(`/proposal?${params.toString()}`);
  };

  return (
    <Box sx={{ paddingTop: 2, paddingLeft: 20, paddingRight: 20 }}>
      <Typography variant="h4" gutterBottom>
        Create a sponsorship package
      </Typography>

      <Paper sx={{ p: 2, border: 1, borderColor: "rgba(0, 0, 0, 0.12)" }}>
        <AudienceForm readOnly={false} handleSubmit={handleSubmit} />
      </Paper>
    </Box>
  );
};
