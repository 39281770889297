import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  CircularProgress,
  IconButton,
  Tooltip,
  Link,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import WarningIcon from "@mui/icons-material/Warning";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { styled } from "@mui/system";
import { RepoData, fetchRepoData } from "../lib/fetchRepoData";

export interface SquareGitHubCardProps {
  repoUrl: string;
  dollar_amount?: string;
  onSelected: () => void;
  isSelected: boolean;
}

const REPO_STALE_THRESHOLD_DAYS = 90;

const boxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const LogoAndName = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const Description = styled(Typography)({
  color: "grey",
  margin: "8px 0",
});

const ButtonsContainer = styled("div")({
  "& > button": {
    marginLeft: "8px",
  },
});

export const SquareGitHubCard: React.FC<SquareGitHubCardProps> = ({
  repoUrl,
  dollar_amount,
  onSelected,
  isSelected,
}) => {
  const [repoData, setRepoData] = useState<RepoData | undefined>(undefined);
  const [finishedFetching, setFinishedFetching] = useState(false);

  const [orgName, projectName] = repoUrl.split("/").slice(-2);

  useEffect(() => {
    if (repoData !== undefined) return;

    fetchRepoData(orgName, projectName).then((data) => {
      setRepoData(data);
      setFinishedFetching(true);
    });
  }, [orgName, projectName, repoData]);

  if (!repoData && finishedFetching) {
    // error fetching the repo

    return <></>;
  }

  if (!repoData && !finishedFetching)
    return (
      <Card
        sx={{
          width: "100%",
          marginBottom: 2,
          p: 2,
          border: "1px solid rgba(0, 0, 0, 0.12)",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Card>
    );

  const repoDataNotNull = repoData!;
  const currentDate = new Date();
  const pushedDate = new Date(repoDataNotNull.pushed_at);
  const repoStaleDays = Math.floor(
    (currentDate.getTime() - pushedDate.getTime()) / (1000 * 60 * 60 * 24)
  );
  const stars = repoDataNotNull.stargazers_count;

  const buttonColor = isSelected ? "error.main" : "success.main";

  return (
    <Card
      sx={{
        width: "100%",
        minHeight: "200px",
        marginBottom: "20px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        cursor: "pointer",
        height: "calc(100% - 20px)",
      }}
    >
      <CardContent>
        <Link
          href={`/repo/${repoDataNotNull.full_name}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <LogoAndName>
                <img
                  src={repoDataNotNull.owner.avatar_url}
                  alt="logo"
                  width="30"
                  height="30"
                />
                <Typography fontWeight="bold">{projectName}</Typography>
              </LogoAndName>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {repoData?.repo_accept_sponsorship && (
                  <Tooltip title="Accept sponsorship publicly">
                    <AttachMoneyIcon color={"success"} />
                  </Tooltip>
                )}
                {stars} stars
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box sx={{ marginBottom: 2 }}>
                <Description>{repoDataNotNull.description}</Description>
                {repoStaleDays > REPO_STALE_THRESHOLD_DAYS && (
                  <Box display={"flex"} gap={1} marginTop={2}>
                    <WarningIcon sx={{ color: "orange" }} />
                    <Typography>
                      Last commit pushed {repoStaleDays} days ago
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-end",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  marginBottom: "16px",
                  alignSelf: "end",
                  textAlign: "end",
                }}
              >
                {dollar_amount}
              </Typography>
            </Grid>
          </Grid>
        </Link>
        <ButtonsContainer
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            color="inherit"
            onClick={(event) => {
              if (onSelected) onSelected();
              event.stopPropagation();
            }}
            sx={{
              backgroundColor: buttonColor,
              color: "white",
              "&:hover": { backgroundColor: buttonColor },
              boxShadow: boxShadow,
              marginBottom: "0px",
              marginLeft: "0px",
              alignSelf: "end",
            }}
          >
            {isSelected ? <DeleteIcon /> : <AddIcon />}
          </IconButton>
        </ButtonsContainer>
      </CardContent>
    </Card>
  );
};
