// UpgradePopup.tsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

interface UpgradePopupProps {
  open: boolean;
  onClose: () => void;
}

const UpgradePopup: React.FC<UpgradePopupProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{"Upgrade to growth tier"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          On growth tier, get access to GPT-generated embedding contents,
          performance reporting, and more.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            window.open(
              "https://calendly.com/tonyyanga/meet-with-tony-devmark"
            );
          }}
        >
          Let's meet
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradePopup;
