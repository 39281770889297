import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  Tooltip,
  Paper,
} from "@mui/material";

import { SignIn, SignedIn, SignedOut, useUser } from "@clerk/clerk-react";
import { Navigate, useNavigate } from "react-router-dom";
import { GitHubProgressCard } from "../components/GitHubProgressCard";
import { GitHubCardProps } from "../components/GitHubCard";

function repoToProject(repo: any): GitHubCardProps {
  return {
    repoUrl: `https://github.com/${repo.repo}`,
  };
}

export const Home: React.FC = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const budget = (user?.publicMetadata as any)?.budget;
  const repos = (user?.publicMetadata as any)?.repos;

  if (user && !repos) {
    return <Navigate to="/new" />;
  }

  return (
    <Box sx={{ paddingTop: 2 }}>
      <SignedIn>
        <Container className="main-container">
          {/*<JSONPretty data={user?.publicMetadata} />*/}
          {repos && (
            <Grid container spacing={2}>
              {budget && (
                <>
                  <Grid item xs={6}>
                    <Paper
                      sx={{
                        border: 1,
                        borderColor: "rgba(0, 0, 0, 0.12)",
                        padding: 2,
                        margin: 2,
                      }}
                    >
                      <Typography variant="h3" gutterBottom>
                        ${budget}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h5" sx={{ color: "grey" }}>
                          Monthly sponsorships budget
                        </Typography>
                        <Tooltip title="Automatic payment disabled">
                          <span>
                            <Button variant="contained" disabled={true}>
                              Billing
                            </Button>
                          </span>
                        </Tooltip>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Paper
                      sx={{
                        border: 1,
                        borderColor: "rgba(0, 0, 0, 0.12)",
                        padding: 2,
                        margin: 2,
                      }}
                    >
                      <Typography variant="h3" gutterBottom>
                        0 / {repos.length}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{ color: "grey" }}
                        >
                          Active sponsorships
                        </Typography>
                        <Button
                          variant="contained"
                          onClick={() => {
                            navigate("/manage");
                          }}
                        >
                          Manage
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>
                </>
              )}
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                {repos.map((repo: any) => (
                  <GitHubProgressCard
                    {...repoToProject(repo)}
                    hide_sponsorship_button={true}
                    show_placement_suggestion={false}
                    prefetch={true}
                    key={repo.url}
                    showMetrics={true}
                  />
                ))}
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          )}
        </Container>
      </SignedIn>
      <SignedOut>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "20vh",
          }}
        >
          <SignIn />
        </Box>
      </SignedOut>
    </Box>
  );
};
