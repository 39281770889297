import React from "react";
import { Box, Typography, Grid, Button, Paper } from "@mui/material";

import { GitHubCard, GitHubCardProps } from "../components/GitHubCard";
import { Navigate, useNavigate } from "react-router-dom";

import { useUser } from "@clerk/clerk-react";
import UpgradePopup from "../components/UpgradePopup";
import { GitHubProgressCard } from "../components/GitHubProgressCard";

function repoToProject(repo: any): GitHubCardProps {
  return {
    repoUrl: `https://github.com/${repo.repo}`,
  };
}

export const ManageSponsorships: React.FC = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const repos = (user?.publicMetadata as any)?.repos;
  const product = (user?.publicMetadata as any)?.product;

  const [popupOpen, setPopupOpen] = React.useState(false);

  if (!repos) {
    return <Navigate to="/new" />;
  }

  return (
    <>
      <Paper
        sx={{
          marginBottom: 2,
          p: 2,
          border: 1,
          borderColor: "rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="h4">Sponsorship package</Typography>
            <Typography variant="h6" color="grey">
              Next step: upgrade and go live
            </Typography>
          </Box>
          <Box>
            <Button
              color="success"
              variant="contained"
              sx={{
                margin: 2,
              }}
              onClick={() => {
                setPopupOpen(true);
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  src="/gradient_gold_royal_crown-removebg-preview.png"
                  alt=""
                  style={{
                    height: 20,
                    width: 20,
                    marginRight: 4,
                    alignSelf: "center",
                  }}
                ></img>
                Upgrade
              </Box>
            </Button>
            <Button
              variant="contained"
              sx={{ maxHeight: 40 }}
              onClick={() => {
                navigate("/new");
              }}
            >
              New package
            </Button>
          </Box>
        </Box>
      </Paper>
      <Grid item xs={12} md={9}>
        {repos.map((repo: any) => (
          <GitHubProgressCard
            {...repoToProject(repo)}
            hide_sponsorship_button={true}
            show_placement_suggestion={false}
            product_description={product!}
            prefetch={true}
            key={repo.url}
            showMetrics={true}
          />
        ))}
      </Grid>
      <UpgradePopup
        open={popupOpen}
        onClose={() => {
          setPopupOpen(false);
        }}
      />
    </>
  );
};
