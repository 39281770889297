import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import { fetchSuggestion } from "../lib/fetchSuggestion";
import { LineDiff } from "./LineDiff";

interface SuggestionPopupProps {
  open: boolean;
  onClose: () => void;
  product: string;
  repoName: string; // e.g. "facebook/react"
  prefetch?: boolean;
}

function processSuggestion(
  suggestion: string
): { before: string; after: string }[] {
  try {
    const obj = JSON.parse(suggestion);
    return obj.map((item: any) => {
      return {
        before: item.before,
        after: item.after,
      };
    });
  } catch (e) {
    console.log("error parsing suggestion", e);
    return [];
  }
}

function showContents(suggestion: string) {
  const jsonContent = processSuggestion(suggestion);

  return (
    <Box sx={{ padding: 1 }}>
      {suggestion === "" ? (
        <LinearProgress />
      ) : jsonContent.length > 0 ? (
        jsonContent.map((item, index) => <LineDiff key={index} {...item} />)
      ) : (
        <p>{suggestion}</p>
      )}
    </Box>
  );
}

export const SuggestionPopup: React.FC<SuggestionPopupProps> = ({
  open,
  onClose,
  product,
  repoName,
  prefetch,
}) => {
  // format: JSON list: [{before: string, after: string}]
  const [suggestion, setSuggestion] = React.useState<string>("");
  const [prefetchedSuggestion, setPrefetchedSuggestion] =
    React.useState<string>("");

  useEffect(() => {
    if (prefetch && prefetchedSuggestion === "") {
      fetchSuggestion(product, repoName).then((suggestion) => {
        setPrefetchedSuggestion(suggestion);
      });
    }
  }, [open, prefetch, prefetchedSuggestion, product, repoName]);

  useEffect(() => {
    if (open && suggestion === "") {
      fetchSuggestion(product, repoName, "gpt-4-32k").then((suggestion) => {
        setSuggestion(suggestion);
      });

      if (!prefetch && open && prefetchedSuggestion === "") {
        fetchSuggestion(product, repoName).then((suggestion) => {
          setPrefetchedSuggestion(suggestion);
        });
      }
    }
  }, [open, prefetch, prefetchedSuggestion, product, repoName, suggestion]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>Suggestion for {repoName} README</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">GPT-3.5-Turbo-16K</Typography>
            {showContents(prefetchedSuggestion)}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6">GPT-4-32K</Typography>
            {showContents(suggestion)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
