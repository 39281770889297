import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Grid,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GitHubCard } from "../components/GitHubCard";
import { useParams } from "react-router-dom";
import { AudienceForm } from "../components/AudienceForm";

export interface ProjectData {
  product_description: string;
  user_description: string;
  sample_projects: Array<{
    repoUrl: string;
    sponsorship_opportunities: string[];
    sponsorship_example: string;
    dollar_amount: string;
    example_popup?: {
      title?: string;
      old_code: string;
      new_code: string;
    };
    // if not present: button will say "Sponsor"
    next_step?: string;
  }>;
}

export const SearchResult: React.FC = () => {
  const { projectName } = useParams();
  const [data, setData] = useState<ProjectData | undefined>(undefined);
  useEffect(() => {
    // Fetch data from the local JSON file
    fetch(`/data/${projectName}.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((jsonData) => {
        setData(jsonData);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [projectName]);

  if (!data) return <div>Loading...</div>;

  return (
    <Box sx={{ paddingTop: 2 }}>
      <Typography variant="h4" gutterBottom>
        Find New Sponsorships
      </Typography>
      <Box sx={{ p: 2, border: 1, borderColor: "rgba(0, 0, 0, 0.12)" }}>
        <AudienceForm projectData={data} readOnly={true} />
      </Box>

      {/* Divider to separate the content */}
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={4} sx={{ marginTop: 3 }}>
        <Grid item xs={12} md={3}>
          <Paper
            elevation={3}
            sx={{
              padding: 2,
              marginBottom: 2,
              border: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Min Amount Per Project</FormLabel>
              <RadioGroup>
                <FormControlLabel
                  value="0-100"
                  control={<Radio />}
                  label="0-100"
                />
                <FormControlLabel
                  value="100-500"
                  control={<Radio />}
                  label="100-500"
                />
                <FormControlLabel
                  value="500+"
                  control={<Radio />}
                  label="500+"
                />
              </RadioGroup>
            </FormControl>
          </Paper>

          <Paper elevation={3} sx={{ padding: 2 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Star Count</FormLabel>
              <RadioGroup>
                <FormControlLabel
                  value="0-500"
                  control={<Radio />}
                  label="0-500"
                />
                <FormControlLabel
                  value="500-1000"
                  control={<Radio />}
                  label="500-1000"
                />
                <FormControlLabel
                  value="1000+"
                  control={<Radio />}
                  label="1000+"
                />
              </RadioGroup>
            </FormControl>
          </Paper>
        </Grid>

        <Grid item xs={12} md={9}>
          {data.sample_projects.map((project, index) => (
            <GitHubCard {...project} key={index} />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
