import { Box } from "@mui/material";

import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";

export const LineDiff: React.FC<{ before: string; after: string }> = ({
  before,
  after,
}) => {
  const styles = {
    variables: {
      dark: {
        addedBackground: "#e6ffed",
        addedColor: "black",
        removedBackground: "#ffeef0",
        removedColor: "black",
      },
      light: {
        addedBackground: "#e6ffed",
        addedColor: "black",
        removedBackground: "#ffeef0",
        removedColor: "black",
      },
    },
    gutter: {
      minWidth: "1em",
    },

    codeDiffContainer: {
      width: "100%", // Ensures the container takes up the full width available
    },
  };
  return (
    <Box sx={{ border: 1, borderColor: "grey", margin: 1 }}>
      <ReactDiffViewer
        oldValue={before}
        newValue={after}
        splitView={false}
        compareMethod={DiffMethod.WORDS} // You can also use DiffMethod.WORDS or DiffMethod.CHARS
        styles={styles}
        useDarkTheme={false} // Change this to true for a dark theme
        showDiffOnly={true}
        hideLineNumbers={true}
      />
    </Box>
  );
};
