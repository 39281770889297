// src/App.tsx
import React from "react";
import { CssBaseline, Container } from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { SideNavbar } from "./components/SideNavbar";
import { TopNavbar } from "./components/TopNavbar";
import { SearchResult } from "./pages/SearchResult";
import "./App.css";
import { NewSearch } from "./pages/NewSearch";
import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import { PostHogProvider } from "posthog-js/react";
import { Proposal } from "./pages/Proposal";
import { Home } from "./pages/Home";
import { ManageSponsorships } from "./pages/Manage";
import { SignInOrUp } from "./components/SignInOrUp";
import { Repo } from "./pages/Repo";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const App: React.FC = () => {
  return (
    <PostHogProvider
      apiKey={"phc_Fz8OsT310rAsJu4Griqm1Ts33CeKG9BTOeESEcF50u7"}
      options={{
        api_host: "https://ph.getmentioned.ai/ingest",
      }}
    >
      <Router>
        <div className="app-container" style={{ backgroundColor: "#fafafa" }}>
          <ClerkProvider publishableKey={clerkPubKey}>
            <CssBaseline />
            <SignedIn>
              <div className="sidebar">
                <SideNavbar />
              </div>
            </SignedIn>
            <div className="main-content">
              <Routes>
                <Route
                  path="/sign-up"
                  element={
                    <>
                      <SignedIn>
                        <Navigate to="/new" />
                      </SignedIn>
                      <Container className="sign-up-container">
                        <SignInOrUp sign_in_flag={false} />
                      </Container>
                    </>
                  }
                ></Route>

                <Route
                  path="/sign-in"
                  element={
                    <>
                      <SignedIn>
                        <Navigate to="/new" />
                      </SignedIn>
                      <Container className="sign-up-container">
                        <SignInOrUp sign_in_flag={true} />
                      </Container>
                    </>
                  }
                ></Route>

                <Route
                  path="/new"
                  element={
                    <>
                      <div className="top-navbar">
                        <TopNavbar />
                      </div>
                      <Container className="main-container">
                        <NewSearch />
                      </Container>
                    </>
                  }
                />

                <Route
                  path="/manage"
                  element={
                    <>
                      <SignedIn>
                        <div className="top-navbar">
                          <TopNavbar />
                        </div>
                        <Container className="main-container">
                          <ManageSponsorships />
                        </Container>
                      </SignedIn>
                      <SignedOut>
                        <Navigate to="/sign-in" />
                      </SignedOut>
                    </>
                  }
                />
                <Route
                  // Search params: product, audience, budget
                  path="/proposal"
                  element={
                    <>
                      <div className="top-navbar">
                        <TopNavbar />
                      </div>
                      <Container className="main-container">
                        <Proposal />
                      </Container>
                    </>
                  }
                />
                <Route
                  path="/repo/:orgName/:repoName"
                  element={
                    <>
                      <div className="top-navbar">
                        <TopNavbar />
                      </div>
                      <Container className="main-container">
                        <Repo />
                      </Container>
                    </>
                  }
                />
                <Route
                  path="/:projectName"
                  element={
                    <>
                      <div className="top-navbar">
                        <TopNavbar />
                      </div>
                      <Container className="main-container">
                        <SearchResult />
                      </Container>
                    </>
                  }
                />

                <Route
                  path="/"
                  element={
                    <>
                      <SignedOut>
                        <Navigate to="/sign-in" />
                      </SignedOut>
                      <div className="top-navbar">
                        <TopNavbar />
                      </div>
                      <Home />
                    </>
                  }
                />
              </Routes>
            </div>
          </ClerkProvider>
        </div>
      </Router>
    </PostHogProvider>
  );
};

export default App;
