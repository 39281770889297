export interface ProposalQuery {
  product: string;
  audience: string;
  website: string | null;
  nonEnglish: string | null;
  lastCommitDate: string | null;
  minStarCount: string | null;
  haveFundingLinks: string | null;
  starGrowthTier: string | null;
}

export function saveProposal(query: ProposalQuery, repos: any[]) {
  localStorage.setItem("proposal.query", JSON.stringify(query));
  localStorage.setItem("proposal.repos", JSON.stringify(repos));
}

export function getProposal():
  | { query: ProposalQuery; repos: any[] }
  | undefined {
  const query = localStorage.getItem("proposal.query");
  const repos = localStorage.getItem("proposal.repos");

  if (query === null || repos === null) {
    return undefined;
  }

  try {
    return {
      query: JSON.parse(localStorage.getItem("proposal.query")!),
      repos: JSON.parse(localStorage.getItem("proposal.repos")!),
    };
  } catch (e) {
    console.error(e);
    return undefined;
  }
}

export function saveSelectedRepo(repoName: string) {
  const sessionItems = localStorage.getItem("proposal.repos.selected");

  let existingItems = [];

  if (sessionItems !== null) {
    existingItems = JSON.parse(sessionItems);
  }

  existingItems.push(repoName);

  const newExistingItems = Array.from(new Set(existingItems));

  localStorage.setItem(
    "proposal.repos.selected",
    JSON.stringify(newExistingItems)
  );
}

export function deleteSelectedRepo(repoName: string) {
  const sessionItems = localStorage.getItem("proposal.repos.selected");

  let existingItems = [];

  if (sessionItems !== null) {
    existingItems = JSON.parse(sessionItems);
  }

  const newExistingItems = existingItems.filter(
    (item: any) => item !== repoName
  );

  localStorage.setItem(
    "proposal.repos.selected",
    JSON.stringify(newExistingItems)
  );
}

export function getSelectedRepo(): string[] {
  if (localStorage.getItem("proposal.repos.selected") === null) {
    return [];
  }

  return JSON.parse(localStorage.getItem("proposal.repos.selected")!);
}

export function clearSelectedRepo() {
  localStorage.removeItem("proposal.repos.selected");
}
