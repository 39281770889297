import React from "react";
import { SignIn, SignUp } from "@clerk/clerk-react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";

interface SignInOrUpProps {
  sign_in_flag: boolean;
}

export const SignInOrUp: React.FC<SignInOrUpProps> = ({ sign_in_flag }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // redirectUrl should always be a relative URL
  const redirectUrl = searchParams.get("redirectUrl");

  console.log("redirectUrl", redirectUrl);

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage:
          'url("https://uploads-ssl.webflow.com/64f77a831b9d50c279dc926c/651daad696f8f7778c7c3d02_33319713_fht1%20(1).jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        paddingLeft: "8vw! important",
      }}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{ color: "white" }}
          >
            Reach your audience
          </Typography>
          <Typography variant="h6" component="p" sx={{ color: "white" }}>
            Find developers who are seeking out your solution.
          </Typography>
          <Typography variant="h6" component="p" sx={{ color: "white" }}>
            They discover your brand and you stay top of mind.
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{ color: "white" }}
          ></Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              borderLeft: `1px solid`,
            }}
          >
            {sign_in_flag ? (
              <SignIn signUpUrl="/sign-up" redirectUrl={redirectUrl ?? "/"} />
            ) : (
              <SignUp signInUrl="/sign-in" redirectUrl={redirectUrl ?? "/"} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
