import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  LinearProgress,
  Grid,
  Button,
  Paper,
} from "@mui/material";

import { useSearchParams } from "react-router-dom";
import { ProposalDetails, Repo } from "../components/ProposalDetails";
import { GitHubCard, GitHubCardProps } from "../components/GitHubCard";
import { useUser } from "@clerk/clerk-react";
import { upsertUserInput } from "../lib/userInput";
import {
  clearSelectedRepo,
  getProposal,
  saveProposal,
} from "../lib/proposalStorage";

function repoToProject(repo: Repo): GitHubCardProps {
  return {
    repoUrl: `https://github.com/${repo.name}`,
  };
}

async function fetchRepos(
  user: string | null | undefined,
  product: string,
  audience: string,
  type: string,
  nonEnglish: string | null,
  lastCommitDate: string | null,
  minStarCount: string | null,
  haveFundingLinks: string | null,
  starGrowthTier: string | null,
  retry: boolean
): Promise<any[]> {
  const queryObject: any = { product, audience, type };
  if (user !== null) {
    queryObject["user"] = user;
  }

  if (nonEnglish !== undefined) {
    queryObject["nonEnglish"] = nonEnglish;
  }

  if (lastCommitDate) {
    queryObject["lastCommitDate"] = lastCommitDate;
  }

  if (minStarCount) {
    queryObject["minStarCount"] = minStarCount;
  }

  if (haveFundingLinks) {
    queryObject["haveFundingLinks"] = haveFundingLinks;
  }

  if (starGrowthTier) {
    queryObject["starGrowthTier"] = starGrowthTier;
  }

  const resp = await fetch("/api/repos/search", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(queryObject),
  });

  if (!resp.ok) {
    console.log(`Error: ${resp.status}`);

    if (retry) {
      return fetchRepos(
        user,
        product,
        audience,
        type,
        nonEnglish,
        lastCommitDate,
        minStarCount,
        haveFundingLinks,
        starGrowthTier,
        false
      );
    } else {
      throw new Error(
        JSON.stringify({
          status: resp.status,
          statusText: resp.statusText,
          reason: await resp.text(),
        })
      );
    }
  }

  const data = await resp.json();
  return data;
}

export const Proposal: React.FC = () => {
  const { user, isLoaded } = useUser();

  const [repoFetchError, setRepoFetchError] = useState<string | null>(null);
  const [initRepos, setInitRepos] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const product = searchParams.get("product");
  const audience = searchParams.get("audience");
  const website = searchParams.get("website");

  useEffect(() => {
    if (product && audience) upsertUserInput(product, audience);
  }, [product, audience]);

  // optional
  const nonEnglish = searchParams.get("nonEnglish");
  const lastCommitDate = searchParams.get("lastCommitDate");
  const minStarCount = searchParams.get("minStarCount");
  const haveFundingLinks = searchParams.get("haveFundingLinks");
  const starGrowthTier = searchParams.get("starGrowthTier");

  const fetchCachedProposal = getProposal();

  let reposFromCache = [];
  if (fetchCachedProposal) {
    const { query, repos } = fetchCachedProposal;

    if (
      product === query.product &&
      audience === query.audience &&
      website === query.website &&
      nonEnglish === query.nonEnglish &&
      lastCommitDate === query.lastCommitDate &&
      minStarCount === query.minStarCount &&
      haveFundingLinks === query.haveFundingLinks &&
      starGrowthTier === query.starGrowthTier
    ) {
      reposFromCache = repos;
    } else {
      clearSelectedRepo();
    }
  }

  const [repos, setRepos] = useState<any[]>(reposFromCache);

  useEffect(() => {
    if (
      isLoaded &&
      product !== null &&
      audience !== null &&
      initRepos.length === 0
    ) {
      fetchRepos(
        user?.primaryEmailAddress?.toString(),
        product,
        audience,
        "init",
        nonEnglish,
        lastCommitDate,
        minStarCount,
        haveFundingLinks,
        starGrowthTier,
        true
      )
        .then((data) => {
          setInitRepos(data);
        })
        .catch((error) => {
          setRepoFetchError(error.message);
        });
    }
  }, [
    audience,
    haveFundingLinks,
    lastCommitDate,
    minStarCount,
    nonEnglish,
    product,
    user?.primaryEmailAddress,
    starGrowthTier,
    initRepos.length,
    isLoaded,
  ]);

  useEffect(() => {
    if (
      isLoaded &&
      product !== null &&
      audience !== null &&
      repos.length === 0
    ) {
      fetchRepos(
        user?.primaryEmailAddress?.toString(),
        product,
        audience,
        "full",
        nonEnglish,
        lastCommitDate,
        minStarCount,
        haveFundingLinks,
        starGrowthTier,
        true
      )
        .then((data) => {
          setRepos(data);
          saveProposal(
            {
              product,
              audience,
              website,
              nonEnglish,
              lastCommitDate,
              minStarCount,
              haveFundingLinks,
              starGrowthTier,
            },
            data
          );
        })
        .catch((error) => {
          setRepoFetchError(error.message);
        });
    }
  }, [
    audience,
    lastCommitDate,
    minStarCount,
    nonEnglish,
    product,
    haveFundingLinks,
    user?.primaryEmailAddress,
    starGrowthTier,
    website,
    repos.length,
    isLoaded,
  ]);

  if (repoFetchError !== null) {
    const { status, statusText, reason } = JSON.parse(repoFetchError);

    return (
      <Box sx={{ padding: 2, border: 1, borderColor: "error.main" }}>
        <Typography variant="h4" gutterBottom>
          Error when generating proposals
        </Typography>
        <Typography variant="h6" gutterBottom>
          Error: {reason}
        </Typography>
        <Typography variant="h6" gutterBottom>
          HTTP {status}: {statusText}
        </Typography>
        <Button
          variant="contained"
          onClick={(_) => {
            window.location.reload();
          }}
          sx={{ marginTop: 2 }}
        >
          Retry
        </Button>
      </Box>
    );
  }

  if (repos.length === 0) {
    return (
      <Box sx={{ paddingTop: 2 }}>
        <Typography variant="h4" gutterBottom>
          Searching for projects to sponsor
        </Typography>
        <LinearProgress />
        {initRepos.length > 0 && (
          <Paper
            sx={{
              marginTop: 4,
              marginBottom: 2,
              p: 2,
              border: 1,
              borderColor: "rgba(0, 0, 0, 0.12)",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Still searching. Here are some candidates we have found...
            </Typography>
            <Grid item xs={12} md={9}>
              {initRepos.map((repo, index) => (
                <GitHubCard
                  {...repoToProject(repo)}
                  hide_sponsorship_button={true}
                  key={repo.url}
                />
              ))}
            </Grid>
          </Paper>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ paddingTop: 2 }}>
      <ProposalDetails
        repos={repos}
        product={product!}
        audience={audience!}
        website={website!}
      />
    </Box>
  );
};
