export async function fetchSuggestion(
  product: string,
  repoName: string,
  model?: string
) {
  const payload: any = { product_description: product, repo_name: repoName };
  if (model) {
    payload["model"] = model;
  }

  const resp = await fetch("/api/embedding/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!resp.ok) {
    console.log(`Error: ${resp.status}`);
    throw new Error(`Error: ${resp.status}`);
  }

  const { suggestion } = await resp.json();
  return suggestion;
}
