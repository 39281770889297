import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { RepoData, fetchRepoData } from "../lib/fetchRepoData";
import {
  Box,
  CircularProgress,
  Grid,
  styled,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Divider,
  Paper,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { RepoMetadata } from "../components/RepoMetadata";
import { fetchRepoReadme } from "../lib/fetchReadme";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGemoji from "remark-gemoji";
import remarkGfm from "remark-gfm";
import { marked } from "marked";
import { useUser } from "@clerk/clerk-react";
import { fetchUserInput } from "../lib/userInput";
import { fetchSuggestion } from "../lib/fetchSuggestion";
import { saveSelectedRepo } from "../lib/proposalStorage";

import LockIcon from "@mui/icons-material/Lock";

const StyledPaper = styled(Paper)({
  border: "1px lightgrey solid",
  borderRadius: "10px",
  marginBottom: "10px",
});

const StyledOptionPaper = styled(Paper)({
  border: "1px #9e9e9e solid",
  padding: "6px",
  margin: "6px",
  borderRadius: "10px",
  maxWidth: "100%",
  overflowWrap: "break-word",
});

function processReadme(
  readme: string,
  suggestions: { before: string; after: string }[]
): string {
  let updatedReadme = readme;
  for (const s of suggestions) {
    if (!updatedReadme.includes(s.before)) {
      console.error("GPT suggested non-existent changes");
      console.error([s.before]);
      continue;
    }

    // find the line in the readme that includes s.before
    const startIdx = updatedReadme.indexOf(s.before);
    const endIdx = startIdx + s.before.length;

    let newLineIdxBefore = updatedReadme
      .substring(0, startIdx)
      .lastIndexOf("\n\n");
    let newLineIdxAfter =
      updatedReadme.substring(endIdx, updatedReadme.length).indexOf("\n\n") +
      endIdx;

    newLineIdxBefore = newLineIdxBefore === -1 ? 0 : newLineIdxBefore;
    newLineIdxAfter =
      newLineIdxAfter === -1 ? updatedReadme.length : newLineIdxAfter;

    // paragraph affected by the suggestion: from newLineIdxBefore to newLineIdxAfter
    const paragraphAffected = updatedReadme.substring(
      newLineIdxBefore,
      newLineIdxAfter
    );

    let paragraphAfterUpdate = paragraphAffected.replace(s.before, s.after);

    paragraphAfterUpdate = `\n\n<div style="background-color: yellow;">${marked(
      paragraphAfterUpdate
    )}</div>`;

    updatedReadme = updatedReadme.replace(
      paragraphAffected,
      paragraphAfterUpdate
    );
  }

  return updatedReadme;
}

export const Repo: React.FC = () => {
  const { orgName, repoName } = useParams();
  const navigate = useNavigate();

  const { user } = useUser();

  const { product: sessionProduct } = fetchUserInput();

  // product description used to generate GPT embedding
  const product = sessionProduct;

  // JSON string. A list of diffs with "before" and "after" keys
  const [suggestion, setSuggestion] = useState("");
  // selected suggestion by index
  const [selectedSuggestions, setSelectedSuggestions] = useState<number[]>([]);

  const [readme, setReadme] = useState<string | undefined>(undefined);

  const parsedSuggestion =
    suggestion === "" || readme === undefined
      ? undefined
      : JSON.parse(suggestion).filter((item: any) =>
          readme.includes(item.before)
        );
  const selectedSuggestionEntries = parsedSuggestion?.filter(
    (_: any, index: number) => selectedSuggestions.includes(index)
  );

  const [repoData, setRepoData] = useState<RepoData | undefined>(undefined);
  const [finishedFetching, setFinishedFetching] = useState(false);

  useEffect(() => {
    if (suggestion === "" && product) {
      fetchSuggestion(product, `${orgName}/${repoName}`, "gpt-4-32k").then(
        (suggestion) => {
          setSuggestion(suggestion);
        }
      );
    }
  }, [product, orgName, repoName, suggestion]);

  useEffect(() => {
    if (repoData !== undefined) return;

    if (orgName === undefined || repoName === undefined) return;

    fetchRepoData(orgName, repoName).then((data) => {
      setRepoData(data);
      setFinishedFetching(true);
    });
  }, [orgName, repoName, repoData]);

  useEffect(() => {
    if (readme !== undefined) return;

    if (orgName === undefined || repoName === undefined) return;

    fetchRepoReadme(`${orgName}/${repoName}`).then((data) => {
      setReadme(data);
    });
  }, [orgName, repoName, readme]);

  if (!repoData && finishedFetching) {
    // error fetching the repo

    return <p>Error fetching the repo</p>;
  }

  if (!repoData && !finishedFetching)
    return (
      <Box sx={{ display: "flex", justifyItems: "center" }}>
        <CircularProgress />
      </Box>
    );

  const repoDataNotNull = repoData!;

  const readmeBox =
    readme === undefined ? (
      <CircularProgress />
    ) : (
      <div style={{ overflow: "auto" }}>
        <Markdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm, remarkGemoji]}
          urlTransform={(url, key, node) =>
            url.startsWith("http")
              ? url
              : `https://github.com/${repoDataNotNull.full_name}/raw/${repoDataNotNull.default_branch}/${url}`
          }
          components={{
            img: ({ node, ...props }) => {
              // props: src, alt, height, weight
              let { src, alt, height, width, title } = props;

              if (typeof width === "string" && width.includes(";")) {
                width = width.split(";")[0];
              }

              if (typeof height === "string" && height.includes(";")) {
                height = height.split(";")[0];
              }

              return (
                <img
                  alt={alt}
                  src={src}
                  title={title}
                  style={{
                    width: width,
                    height: height,
                    maxWidth: "100%",
                  }}
                />
              );
            },
            code: ({ node, ...props }) => {
              const newLine = props.children?.toString().includes("\n");

              const blockStyle = {
                display: "block",
                overflow: "auto",
                backgroundColor: "#f5f8fa",
                padding: "16px",
              };

              const inlineStyle = {
                display: "inline",
                overflow: "auto",
                backgroundColor: "#f5f8fa",
              };

              return (
                <pre style={newLine ? blockStyle : inlineStyle}>
                  <code>{props.children}</code>
                </pre>
              );
            },
          }}
        >
          {processReadme(readme, selectedSuggestionEntries ?? [])}
        </Markdown>
      </div>
    );

  const marketingOptions =
    suggestion === "" ? (
      <Box sx={{ margin: 2 }}>
        <LinearProgress />
        <Typography marginTop={1}>Generating promotion options</Typography>
        <Button
          variant="contained"
          sx={{ marginTop: 2, width: "100%" }}
          onClick={() => {
            saveSelectedRepo(repoDataNotNull.full_name);
            navigate(-1);
          }}
        >
          Add to cart
        </Button>
      </Box>
    ) : (
      <Box sx={{ margin: 2 }}>
        <Typography variant="h6" fontWeight={"bold"} gutterBottom>
          Promotion options
        </Typography>
        <FormGroup>
          {parsedSuggestion.map((suggestion: any, index: number) => {
            return (
              <StyledOptionPaper key={index}>
                <FormControlLabel
                  control={<Checkbox />}
                  onChange={(_, checked) => {
                    if (checked) {
                      setSelectedSuggestions((prev) => {
                        if (!prev.includes(index)) {
                          return [...prev, index];
                        } else {
                          return prev;
                        }
                      });
                    } else {
                      setSelectedSuggestions((prev) => {
                        if (prev.includes(index)) {
                          return prev.filter((item) => item !== index);
                        } else {
                          return prev;
                        }
                      });
                    }
                  }}
                  label={`README option ${index + 1}`}
                />
                <Typography color={"gray"} sx={{ fontSize: 14 }}>
                  {suggestion.after}
                </Typography>
              </StyledOptionPaper>
            );
          })}
          <StyledOptionPaper>
            <FormControlLabel
              control={<Checkbox disabled />}
              label={`Integration guide`}
            />
            <Box
              sx={{
                display: "inline",
              }}
            >
              <Tooltip title="Upgrade to growth plan to access tutorials">
                <LockIcon
                  sx={{
                    width: 20,
                    height: 20,
                    verticalAlign: "middle",
                    color: "orange",
                  }}
                />
              </Tooltip>
            </Box>
          </StyledOptionPaper>
          <Button
            variant="contained"
            sx={{ marginTop: 2 }}
            onClick={() => {
              saveSelectedRepo(repoDataNotNull.full_name);
              navigate(-1);
            }}
          >
            Add to cart
          </Button>
        </FormGroup>
      </Box>
    );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <StyledPaper>
            <RepoMetadata {...repoDataNotNull} />
          </StyledPaper>
          <StyledPaper>
            <Typography
              fontWeight={"bold"}
              marginTop={1}
              marginBottom={1}
              marginLeft={4}
            >
              README.md
            </Typography>
            <Divider></Divider>
            <Box sx={{ margin: 4 }}>{readmeBox}</Box>
          </StyledPaper>
        </Grid>
        <Grid item xs={4}>
          <StyledPaper>
            {product ? (
              marketingOptions
            ) : (
              <Box sx={{ margin: 2 }}>
                <Typography>
                  Login to see available promotion options
                </Typography>
              </Box>
            )}
          </StyledPaper>
          {/*<StyledPaper>
            <p>github statistics</p>
          </StyledPaper>*/}
        </Grid>
      </Grid>
    </>
  );
};
