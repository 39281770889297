import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export function useHistoryState<T>(
  key: string,
  initialValue: T
): [T, (t: T) => void] {
  const navigate = useNavigate();
  const location = useLocation();

  const [rawState, rawSetState] = useState<T>(() => {
    const value = (location.state as any)?.[key];
    return value ?? initialValue;
  });
  function setState(value: T) {
    navigate(location.pathname, {
      replace: true,
      state: {
        ...location.state,
        [key]: value,
      },
    });
    rawSetState(value);
  }
  return [rawState, setState];
}
